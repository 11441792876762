import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Perfil cliente";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [cliente, setCliente] = useState({});
  const { id_cliente } = useParams();

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("xd03/perfil/" + id_cliente, {})
      .then((json) => {
        setCliente(json.cliente);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No se han podido cargar los datos del cliente: " + e.message);
      });
  }, []);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <p className="lead">{cliente.nombre}</p>
        <div className="row">
          <div className="col-md mb-3">
            <h2>Datos</h2>
            <table className="table table-striped table-bordered mb-3">
              <tbody>
                <tr>
                  <th className="text-center" scope="row">
                    DNI
                  </th>
                  <td className="text-center">{cliente["dni"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Teléfono
                  </th>
                  <td className="text-center">{cliente["telefono"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Agencia
                  </th>
                  <td className="text-center">{cliente["agencia"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Tipo de entrega
                  </th>
                  <td className="text-center">{cliente["tipo_entrega"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Saldo
                  </th>
                  <td className="text-center">S/ {cliente["saldo"]}</td>
                </tr>
              </tbody>
            </table>
            {cliente.telefono && (
              <>
                <a href={"tel:" + cliente["telefono"]} className="btn btn-primary me-3 mb-3" role="button">
                  <i className="bi bi-telephone-fill"></i> Llamar
                </a>
                <a href={"https://wa.me/" + cliente["telefono"]} target="_blank" className="btn btn-success me-3 mb-3" role="button">
                  <i className="bi bi-whatsapp"></i> Ir WhatsApp
                </a>
              </>
            )}
            <button type="button" className="btn btn-secondary mb-3" onClick={() => navigate(-1)}>
              Regresar
            </button>
          </div>
          <div className="col-md mb-3">
            <h2>Destino</h2>
            <p>{cliente["destino"]}</p>
            <h2>Observaciones</h2>
            <p>{cliente["observaciones"].split("\n").map((parrafo) => {
                  return (
                    <>
                      {parrafo}
                      <br />
                    </>
                  );
                })}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
