import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Crear pedido";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [clientes, setClientes] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([]);
  const [busquedaCliente, setBusquedaCliente] = useState("");

  const [today] = useState(new Date());

  const [cliente, setCliente] = useState({ id_cliente: 0, nombre: "" });
  const [agencia, setAgencia] = useState("");
  const [destino, setDestino] = useState("");
  const [tipoEntrega, setTipoEntrega] = useState("");

  const [comprobandoExistenciaPedido, setComprobandoExistenciaPedido] = useState(false)

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("va01/crear_pedido/", {})
      .then((json) => {
        setClientes(json.clientes);
        setClientesFiltrados(json.clientes);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => alert("No se ha podido cargar la lista de clientes: " + e));
  }, []);

  useEffect(() => {
    let filtrado = clientes.filter((fila) => {
      if (fila.nombre.toUpperCase().includes(busquedaCliente.toUpperCase()) || fila.dni.includes(busquedaCliente) || fila.telefono.includes(busquedaCliente)) {
        return true;
      }
      return false;
    });
    setClientesFiltrados(filtrado);
  }, [busquedaCliente]);

  function handleSubmit(e) {
    e.preventDefault();
    setComprobandoExistenciaPedido(true)
    let formData = new FormData(e.currentTarget);
    peticion("va01/comprobar_existencia_pedido_cliente/" + cliente.id_cliente).then((json) => {
      setComprobandoExistenciaPedido(false)
      if (json.cantidad > 0) {
        alert("Este cliente ya tiene un pedido abierto, no puede crear otro.");
      } else {
        setEstado(ESTADOS.CARGANDO);
        peticion("va01/proceso/", {
          method: "POST",
          body: formData,
        })
          .then((json) => {
            navigate("../"+json.operacion+"/modificar/" + json.id_pedido);
          })
          .catch((e) => alert("El pedido no ha podido ser creado correctamente: " + e.message));
      }
    }).catch(e=>alert("No se ha podido comprobar si ya exite otro pedido abierto para este cliente: "+e));
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <form method="post" onSubmit={handleSubmit} className="row g-3">
          <input type="hidden" name="id_cliente" value={cliente.id_cliente} />
          <div className="col-md-8">
            <label className="form-label">Cliente</label>
            <div className="input-group">
              <input type="text" readOnly disabled className="form-control" value={cliente.nombre} />
              <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#clientesModal">
                <i className="bi bi-search"></i> Buscar
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <label for="fecha" className="form-label">
              Fecha
            </label>
            <input
              type="date"
              required
              className="form-control"
              name="fecha"
              id="fecha"
              defaultValue={today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)}
            />
          </div>
          <div className="col-md-4">
            <label for="tipo_entrega" className="form-label">
              Tipo de entrega
            </label>
            <select className="form-select" id="tipo_entrega" name="tipo_entrega" value={tipoEntrega} onChange={(e) => setTipoEntrega(e.target.value)}>
              <option value="Recojo">Recojo</option>
              <option value="Envío">Envío</option>
            </select>
          </div>
          {tipoEntrega == "Envío" && (
            <>
              <div id="divAgencia" className="col-md-4">
                <label for="agencia" className="form-label">
                  Agencia
                </label>
                <input type="text" className="form-control" id="agencia" name="agencia" value={agencia} />
              </div>
              <div id="divCostoEnvio" className="col-md-4">
                <label for="costo_envio" required className="form-label">
                  Costo de envío
                </label>
                <input type="number" step="0.01" min="0.00" className="form-control" id="costo_envio" name="costo_envio" defaultValue={5} />
              </div>
              <div id="divDestino" className="col-md-12">
                <label for="destino" className="form-label">
                  Destino
                </label>
                <input type="text" className="form-control" name="destino" value={destino} />
              </div>
            </>
          )}
          <div className="col-md-12">
            <label for="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea name="observaciones" className="form-control" rows="5"></textarea>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-success me-3 mb-3" disabled={!cliente.nombre.length || comprobandoExistenciaPedido}>
              {comprobandoExistenciaPedido ? <><span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Creando pedido...</> : "Crear pedido"}
            </button>
            <button type="button" className="btn btn-danger me-3 mb-3" onClick={() => navigate(-1)}>
              Cancelar
            </button>
          </div>
        </form>

        <div className="modal fade" id="clientesModal" tabindex="-1">
          <div className="modal-dialog modal-xl modal-fullscreen-sm-down">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">Seleccionar cliente</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div className="modal-body d-flex flex-column">
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="Buscar por nombre, teléfono o DNI..."
                    value={busquedaCliente}
                    onChange={(e) => setBusquedaCliente(e.target.value)}
                    className="form-control"
                    id="busqueda"
                  />
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th className="align-middle text-center" scope="col">
                          Seleccionar
                        </th>
                        <th className="align-middle text-center" scope="col">
                          Nombre
                        </th>
                        <th className="align-middle text-center" scope="col">
                          Teléfono
                        </th>
                        <th className="align-middle text-center" scope="col">
                          DNI
                        </th>
                        <th className="align-middle text-center" scope="col">
                          Saldo
                        </th>
                      </tr>
                    </thead>
                    <tbody id="tabla">
                      {clientesFiltrados.map((cliente) => (
                        <tr>
                          <td className="align-middle text-center">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setAgencia(cliente.agencia);
                                setCliente({ nombre: cliente.nombre, id_cliente: cliente.id_cliente });
                                setDestino(cliente.destino);
                                setTipoEntrega(cliente.tipo_entrega);
                              }}
                            >
                              Seleccionar
                            </button>
                          </td>
                          <td className="align-middle text-center">{cliente.nombre}</td>
                          <td className="align-middle text-center">{cliente.telefono}</td>
                          <td className="align-middle text-center">{cliente.dni}</td>
                          <td className="align-middle text-center">{cliente.saldo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <main className="container p-3">
        <h1>{nombreOperacion}</h1>
        {contenido}
      </main>
    </>
  );
}
