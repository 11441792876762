import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Detalle producto";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);

  const { id_producto } = useParams();

  const [producto, setProducto] = useState({});

  useEffect(() => {
    peticion("mm03/detalle/" + id_producto, {})
      .then((json) => {
        setProducto(json.producto);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los detalles del producto: " + e);
      });
  }, []);

  const navigate = useNavigate();
  const peticion = usePeticion();

  function resetear() {
    setEstado(ESTADOS.CARGADO);
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <p className="lead">{producto["nombre"]}</p>
        <div className="row">
          <div className="col-md mb-2">
            <img className="img-thumbnail mb-3 w-100 d-block" src={API_URL + "static/img/productos/" + producto["id_producto"] + "/principal.jpeg"} />
            <div className="">
              <Link role="button" className="btn btn-secondary mb-3" to="../">
                Regresar
              </Link>
            </div>
          </div>
          <div className="col-md mb-3">
            <table className="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th className="text-center" scope="row">
                    Cantidad buen estado
                  </th>
                  <td className="text-center">{producto["cantidad_buen_estado"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Cantidad para liquidación
                  </th>
                  <td className="text-center">{producto["cantidad_para_liquidacion"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Cantidad merma
                  </th>
                  <td className="text-center">{producto["cantidad_merma"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Puntaje por venta
                  </th>
                  <td className="text-center">{producto["puntaje_venta"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Precio importador
                  </th>
                  <td className="text-center">{producto["precio_importador"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="row">
                    Precio final
                  </th>
                  <td className="text-center">{producto["precio_final"]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {producto.link && (
          <div className="row">
            <h2>Link</h2>
            <table className="table align-middle text-center">
              <tbody>
                <tr>
                  <td className="text-center text-break" scope="row">
                    {producto.link}
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <a className="btn btn-success" role="button" target="_blank" href={producto.link}>
                      <i className="bi bi-whatsapp"></i> Ir grupo WhatsApp
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="row">
          <h2>Descripción</h2>
          <p>
            {producto["detalle"].split("\n").map((parrafo) => {
              return (
                <>
                  {parrafo}
                  <br />
                </>
              );
            })}
          </p>
        </div>
      </>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
