import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Modificar producto";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);

  const { id_producto } = useParams()

  const [producto, setProducto] = useState({})

  useEffect(()=>{
    peticion("mm02/modificar/"+id_producto, {})
    .then((json)=>{
      setProducto(json.producto)
      setEstado(ESTADOS.CARGADO)
    })
    .catch(e=>{
      alert("No han podido ser cargados los datos del producto a modificar "+e)
    })
  }, [])

  const navigate = useNavigate();
  const peticion = usePeticion();

  function handleSubmit(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO);
    let formData = new FormData(e.currentTarget);
    formData.append("id_producto", id_producto)
    peticion("mm02/proceso/", {
      method: "POST",
      body: formData,
    })
      .then((json) => {
        setEstado(ESTADOS.EXITO);
      })
      .catch((e) => alert("El producto no ha podido ser modificado correctamente: " + e.message));
  }

  function resetear() {
    setEstado(ESTADOS.CARGADO);
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <form method="post" className="row g-3" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-md-8">
          <label for="nombre" className="form-label">
          Código | Nombre
          </label>
          <div className="input-group d-flex">
            <input type="text" required="" defaultValue={producto.codigo} className="form-control flex-grow-1" name="codigo" id="codigo" />
            <input type="text" required="" defaultValue={producto.nombre} className="form-control" style={{flex: 3}} name="nombre" id="nombre" />
          </div>
        </div>
        <div className="col-md-4">
          <label for="imagen" className="form-label">
            Imagen
          </label>
          <input accept="image/*" className="form-control" name="imagen" type="file" id="imagen" />
          <div className="form-text">Si no se sube una imagen, se conservará la anterior.</div>
        </div>
        <div className="col-md-4">
          <label for="precio_importador" className="form-label">
            Precio importador
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="precio_importador" id="precio_importador" defaultValue={producto.precio_importador} />
        </div>
        <div className="col-md-4">
          <label for="precio_final" className="form-label">
            Precio final
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="precio_final" id="precio_final" defaultValue={producto.precio_final} />
        </div>
        <div className="col-md-4">
          <label for="comision" className="form-label">
            Puntaje por venta
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="puntaje_venta" id="puntaje_venta" defaultValue={producto.puntaje_venta} />
        </div>
        {/* <div className="col-md-4">
          <label for="cantidad_buen_estado" className="form-label">
            Cantidad buen estado
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_buen_estado" id="cantidad_buen_estado" defaultValue={producto.cantidad_buen_estado} />
        </div>
        <div className="col-md-4">
          <label for="cantidad_para_liquidacion" className="form-label">
            Cantidad para liquidación
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_para_liquidacion" id="cantidad_para_liquidacion" defaultValue={producto.cantidad_para_liquidacion} />
        </div>
        <div className="col-md-4">
          <label for="cantidad_merma" className="form-label">
            Cantidad merma
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_merma" id="cantidad_merma" defaultValue={producto.cantidad_merma} />
        </div> */}
        <div className="col-md-12">
          <label for="link" className="form-label">
            Link
          </label>
          <input type="url" className="form-control" name="link" defaultValue={producto.link} id="link" />
        </div>
        <div className="col-md-12">
          <label for="detalle" className="form-label">
            Detalle
          </label>
          <textarea className="form-control" id="detalle" name="detalle" rows="5" defaultValue={producto.detalle} />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-warning me-3 mb-3">
            Modificar producto
          </button>
          <button type="button" className="btn btn-danger me-3 mb-3" onClick={() => navigate(-1)}>
            Cancelar
          </button>
        </div>
      </form>
    );
  } else if (estado === ESTADOS.EXITO) {
    contenido = (
      <Exito titulo="Producto modificado con éxito" mensaje="El producto ha sido modificado de forma satisfactoria">
        <Link role="button" className="btn btn-primary" to="../">
          Modificar otro producto
        </Link>
      </Exito>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
