import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Consultar pedido";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [pedidos, setPedidos] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("todos");
  const [filtroSinPagar, setFiltroSinPagar] = useState("todos")
  const [busqueda, setBusqueda] = useState("");

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("va03/pedidos/", {})
      .then((json) => {
        setPedidos(json.pedidos);
        setPedidosFiltrados(json.pedidos);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los pedidos correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  useEffect(() => {
    let filtrado = pedidos.filter((fila) => {
      let a, b, c;
      if (
        fila.nombre.toUpperCase().includes(busqueda.toUpperCase()) ||
        `${new Date(fila["fecha"]).getUTCDate()}/${new Date(fila["fecha"]).getUTCMonth() + 1}/${new Date(fila["fecha"]).getUTCFullYear()}`
          .toLocaleString()
          .includes(busqueda.toUpperCase()) ||
        fila.nombre_vendedor.toUpperCase().includes(busqueda.toUpperCase()) ||
        fila.dni.includes(busqueda) ||
        fila.telefono.includes(busqueda) ||
        fila.id_pedido.toString().includes(busqueda)
      ) {
        a = true;
      } else {
        a = false;
      }
      if (filtroEstado != "todos") {
        if (filtroEstado == fila.estado) {
          b = true;
        } else {
          b = false;
        }
      } else {
        b = true;
      }
      if (filtroSinPagar != "todos") {
        if (filtroSinPagar == 0 && fila.sin_pagar == 0 ) {
          c = true;
        } else if (filtroSinPagar == 1 && fila.sin_pagar >= 1) {
          c = true;
        } else {
          c = false;
        }
      } else {
        c = true;
      }
      return a && b && c;
    });
    setPedidosFiltrados(filtrado);
  }, [busqueda, filtroEstado, filtroSinPagar]);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="7">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = pedidosFiltrados.slice(0, 1000).map((pedido) => (
      <tr>
        <td className="align-middle text-center">
          <Link to={"consultar/" + pedido.id_pedido} role="button" className="btn btn-primary">
            Consultar
          </Link>
        </td>
        <td className="align-middle text-center">{pedido.id_pedido}</td>
        <td className="align-middle text-center">{`${new Date(pedido["fecha"]).getUTCDate()}/${new Date(pedido["fecha"]).getUTCMonth() + 1}/${new Date(
          pedido["fecha"]
        ).getUTCFullYear()}`}</td>
        <td className="align-middle text-center">{pedido.nombre}</td>
        {/* {pedido.sin_pagar > 0 ? <td className="align-middle text-center table-danger">{pedido.sin_pagar}</td> : <td className="align-middle text-center table-success">{pedido.sin_pagar}</td>} */}
        <td className="align-middle text-center">{pedido.nombre_vendedor}</td>
        <td className="align-middle text-center">{pedido.estado}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <p>En esta operación se muestran los pedidos en todos los estados</p>
      <form>
        <div className="row g-3 mb-3">
          <div className="col-sm-6">
            <input
              type="text"
              placeholder="Buscar por número, vendedor, fecha, cliente, teléfono o DNI..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              className="form-control"
              id="busqueda"
            />
          </div>
          <div className="col-sm-3">
            <select className="form-select" value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)} aria-label="Default select example">
              <option value="todos">(Todos) Estado...</option>
              <option value="abierto">Abierto</option>
              <option value="completado">Completado</option>
              <option value="entregado">Entregado</option>
            </select>
          </div>
          <div className="col-sm-3">
            <select className="form-select" value={filtroSinPagar} onChange={(e) => setFiltroSinPagar(e.target.value)} aria-label="Default select example">
              <option value="todos">(Todos) S/P...</option>
              <option value="0">Todo pagado</option>
              <option value="1">Con artículos sin pagar</option>
            </select>
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Consultar
              </th>
              <th className="align-middle text-center" scope="col">
                Número
              </th>
              <th className="align-middle text-center" scope="col">
                Fecha
              </th>
              <th className="align-middle text-center" scope="col">
                Cliente
              </th>
              {/* <th className="align-middle text-center" scope="col">
                S/P
              </th> */}
              <th className="align-middle text-center" scope="col">
                Vendedor
              </th>
              <th className="align-middle text-center" scope="col">
                Estado
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
