import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Autorizaciones por usuario";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [usuarios, setUsuarios] = useState([])

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("su01/usuarios/")
      .then((json) => {
        setUsuarios(json.usuarios);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los usuarios correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="6">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = usuarios.map((usuario) => (
      <tr>
        <td className="align-middle text-center">
          <Link to={"modificar/" + usuario.id_usuario} role="button" className="btn btn-primary">
            Seleccionar
          </Link>
        </td>
        <td className="align-middle text-center">{usuario.id_usuario}</td>
        <td className="align-middle text-center">{usuario.nombre}</td>
        <td className="align-middle text-center">{usuario.contrasenia}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Seleccionar
              </th>
              <th className="align-middle text-center" scope="col">
                Id
              </th>
              <th className="align-middle text-center" scope="col">
                Nombre
              </th>
              <th className="align-middle text-center" scope="col">
                Contraseña
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
