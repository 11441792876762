import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Movimiento de inventario";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [cambios, setCambios] = useState([]);
  const [cambiosFiltrados, setCambiosFiltrados] = useState([]);
  const [busqueda, setBusqueda] = useState("");

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("mb51/", {})
      .then((json) => {
        setCambios(json.cambios);
        setCambiosFiltrados(json.cambios);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los cambios de inventario correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  useEffect(() => {
    let filtrado = cambios.filter((fila) => {
      if (fila.nombre.toUpperCase().includes(busqueda.toUpperCase()) || (new Date(fila.fecha)).toLocaleString().includes(busqueda) || fila.codigo.toUpperCase().includes(busqueda.toUpperCase())) {
        return true;
      }
      return false;
    });
    setCambiosFiltrados(filtrado);
  }, [busqueda]);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="7">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = cambiosFiltrados.map((producto) => (
      <tr>
        <td className="align-middle text-center">{(new Date(producto.fecha)).toLocaleString()}</td>
        <td className="align-middle text-center">{producto.codigo}</td>
        <td className="align-middle text-center">{producto.nombre}</td>
        <td className="align-middle text-center">{producto.operacion}</td>
        <td className="align-middle text-center">{producto.variacion_buen_estado}</td>
        <td className="align-middle text-center">{producto.variacion_para_liquidacion}</td>
        <td className="align-middle text-center">{producto.variacion_merma}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <form>
        <div className="row mb-3">
          <div className="col-sm">
            <input
              type="text"
              placeholder="Buscar producto por nombre, código o fecha y hora..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              className="form-control"
              id="busqueda"
            />
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Fecha
              </th>
              <th className="align-middle text-center" scope="col">
                Código
              </th>
              <th className="align-middle text-center" scope="col">
                Nombre
              </th>
              <th className="align-middle text-center" scope="col">
                Operación
              </th>
              <th className="align-middle text-center" role="button" scope="col">
                Buen estado
              </th>
              <th className="align-middle text-center" role="button" scope="col">
                Para liquidación
              </th>
              <th className="align-middle text-center" role="button" scope="col">
                Merma
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
